@import "../../styles/variables";
@import "../../styles/bako.scss";

.navbar-hamburger {
  .hamburger-button {
    background: none;
    border: none;
    &:active,
    &:focus,
    &:hover {
      color: white !important;
    }
  }

  .page-name-text {
    font-weight: bold;
  }

  .dropdown-toggle {
    margin-left: 0.5rem;
    margin-top: 0.5rem;

    background: none;
  }
}

@include media-breakpoint-up(lg) {
  .navbar-hamburger {
    .dropdown-toggle {
      margin-left: 1rem;
      margin-top: auto;
    }
  }
}
