@import "../../styles/variables";
@import "../../styles/bako.scss";

.bakery-name {
  font-size: 1rem;
  font-weight: bold;
  color: $pixi-white;
}

.hamburger-navbar-dropdown {
  border-color: $pixi-navbar-border !important;
  &.profile {
    border-radius: 50%;
    padding-top: 11px;
    padding-bottom: 11px;
  }
}

.below-top-nav {
  margin-top: $custom-layout-margin;
  margin-bottom: $custom-layout-margin;
  transition: $sidebar-transition-time padding-left ease-out;

  &.regular-menu {
    @media (min-width: 992px) and (max-width: 1199.9px) {
      padding-left: $mini-sidebar-width;
    }
    @media (min-width: 1200px) {
      padding-left: $sidebar-width;
    }
  }
  &.toggled-menu {
    @media (min-width: 992px) and (max-width: 1199.9px) {
      padding-left: $sidebar-width;
    }
    @media (min-width: 1200px) {
      padding-left: $mini-sidebar-width;
    }
  }
}

.dropdown-bakery-name {
  margin-left: 0.5rem;
  .dropdown-menu {
    max-height: 30rem;
    overflow: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

@include media-breakpoint-up(lg) {
  .dropdown-bakery-name {
    margin: auto;
  }
}
