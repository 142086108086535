$pixi-pink: #ee4d87;
$pixi-gray: #434c55;
$login-bg: #d0d1ff;
$pixi-white: #ffffff;
$card-radius: 4px;
$pixi-radius: 4px;
$button-radius: $pixi-radius;
$navbar-height: 80px;
$pixi-sidebar-header: #333;
$pixi-sidebar-text: #727272;
$pixi-sidebar-hover: #f2f2f2;
$pixi-black: #000;
$pixi-text-black: #303239;
$pixi-light-blue: #2086ff;
$pixi-pricing-light-blue: #99cfe0;
$pixi-light-gray: #cecece;
$pixi-header-color: #263e47;
$testimonial-pink: #e29fab;
$pixi-testimonials-bg: rgba(214, 117, 135, 0.71);
$pixi-blue-green-light-bg: rgba(21, 158, 158, 0.6);
$footer-height: 200px;
$pixi-blue-green-light2: #159e9e;
$pixi-blue-green-light: #7ebfbf;
$pixi-blue-green-dark: #263e47;
$learn-more-blue: #afd3d4;
$learn-more-light-blue: #add8e6;
$pixi-border-color: #ced4da;
$pixi-sidenav-hover-color: #6495ed;
$pixi-central-background: #f4f8f9;
$how-it-works-button: #35a299;
$input-border-color: #ced4da;
$pixi-shadow-color: #8080801a;
$pixi-navbar-border: #e1e3e5;

// Sizing
$sidebar-width: 220px;
$mini-sidebar-width: 50px;
$card-radius: 8px;
$custom-layout-margin: 75px;
$internal-navbar-height: 83.75px;

//time
$sidebar-transition-time: 0.3s;

// Indices
$sidenav-index: 20;
$data-layout-index: 19;
$data-table-header-index: 18;

// Data table variables
$table-heading-size: 120px; // size occupied by sticky part of the heading

// Fonts
$primary-font-family: "Open Sans", sans-serif;
$pixi-primary-font-family: "Montserrat", sans-serif;
$pixi-secondary-font-family: "Outfit", sans-serif;
$pricing-blue-font-family: "Playball", sans-serif;

// To be retained after pixi integration changes
// $primary: #f1b607;

// To be removed after pixi integration changes
$primary: #00B5E2;
$secondary:  #DBF7FF;
$tertiary: #00B5E2;
$pixi-primary: $primary;
$border-radius: 0.3rem;
