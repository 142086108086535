@import "styles/_variables.scss";

// Modal
.modal-wrapper {
  .modal.show {
    overflow: auto;
  }

  .modal.fade.show {
    display: block;
  }

  .modal-backdrop {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .loader {
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
    background: none;

    img {
      top: 50%;
      left: 50%;
      height: 150px;
      position: absolute;
      transform: translate3d(-50%, -50%, 0) scale(0.5);
    }
    .spinner-border {
      width: 4.5rem;
      height: 4.5rem;
      color: $pixi-primary;
      border: 0.4rem solid;
      border-right-color: transparent;
    }
  }
}
