@import "styles/_variables.scss";

$collapse-btn-height: 50px;

@keyframes sidenav-slidein {
  from {
    width: $mini-sidebar-width;
  }
  to {
    width: $sidebar-width;
  }
}

@keyframes sidenav-slideout {
  from {
    width: $sidebar-width;
  }
  to {
    width: $mini-sidebar-width;
  }
}
@keyframes sidenav-slidein-mobile {
  from {
    width: $sidebar-width;
  }
  to {
    width: 0;
  }
}

@keyframes sidenav-slideout-mobile {
  from {
    width: 0;
  }
  to {
    width: $sidebar-width;
  }
}

.regular-sidenav {
  overflow: hidden;
  animation-name: sidenav-slidein;
  animation-duration: $sidebar-transition-time;
  @media (max-width: 991.9px) {
    animation-name: sidenav-slidein-mobile;
  }
  @media (min-width: 992px) and (max-width: 1199.9px) {
    animation-name: sidenav-slideout;
  }
}

.toggled-sidenav {
  overflow: hidden;
  animation-name: sidenav-slideout;
  animation-duration: $sidebar-transition-time;
  animation-fill-mode: forwards;
  @media (max-width: 991.9px) {
    animation-name: sidenav-slideout-mobile;
    &.side-nav {
      box-shadow: 0 0 20px #dbdad9;
    }
  }
  @media (min-width: 992px) and (max-width: 1199.9px) {
    animation-name: sidenav-slidein;
  }
}

.hoverable {
  &:hover {
    filter: brightness(90%);
  }
}

.side-nav {
  margin-top: $custom-layout-margin;
  height: calc(100vh - #{$custom-layout-margin});
  position: fixed;
  top: 0;
  z-index: $sidenav-index;
  .sidebar {
    height: 100%;
    font-weight: 500;
    box-sizing: border-box;
    background-color: $pixi-white;
    transition: all $sidebar-transition-time;
    .sidenav-list-items {
      .child-item {
        padding-left: $mini-sidebar-width;
      }
      .route-icon {
        display: none;
      }
    }
    .item-icon {
      min-width: $mini-sidebar-width;
    }
  }

  .regular-width {
    width: $sidebar-width;
    @media (min-width: 992px) and (max-width: 1199.9px) {
      width: $mini-sidebar-width;
    }
    @media (max-width: 991.9px) {
      width: 0;
    }
  }

  .toggled-width {
    width: $mini-sidebar-width;
    @media (max-width: 1199.9px) {
      width: $sidebar-width;
    }
  }

  .setting-nav {
    margin-bottom: $collapse-btn-height;
  }
}
